body{
    font-family: IBM Plex Sans;
}

.pateint-heading{
    margin-right: 38px;
}
.div-space{

}

.space-white{
    white-space: nowrap !important;
}
.px-large-5{
    padding-left: 3rem;
    padding-right: 3rem;
}
.bg-light-grey-rad{
    border-radius: 12px;
background: #E1E5E9;
box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;
padding: 10px 0px 11px 4px !important;
}
.bt-hov{
    padding: 2px !important;
}
.bt-hov:hover{
    background: white !important;
    border: 2px solid white !important;
}
.bt-hov:focus{
    background: white !important;
    border: 2px solid white !important;
}
.bt-hov.active{
    background: white !important;
    border: 2px solid white !important;
}

.bg-light-pa{
    background-color: #F7F8F9;
}
.border-light-g{
    /* border: 1px solid #a6a5a5; */
    border-radius: 8px !important;


}
.label-mar{
margin-left: 43px;
padding-top: 4px;
font-weight: 400;
display: flex;
}
.label-mar svg{
    margin-left: 12px;
   
    }
.th-hov{
    padding-bottom: 11px !important;
    /* display: flex; */
    font-weight: 400;
    text-transform: capitalize;
}
.th-hov svg{
    margin-left: 12px;
}
tr{
    border-bottom: 1px solid white !important;
}
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #D9EDEE !important;
  }
 tr:hover .space-up{

visibility: visible;
 }
 td .space-up{
   
    visibility: hidden;
     }

.border-t{
    border-bottom: 1px solid #ddd !important;
}
td{
    white-space: nowrap;
}
.patient-list .search-box {
    min-width: 328px;
}

@media (max-width:575px){
    .div-space{
        flex-direction: column !important;
    }
    .pateint-heading{
        margin-right: 10px;
    }
    .px-large-5{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .right-side{
        margin-left: auto;
        margin-bottom: 18px;
    }
    .navbar-brand{
        margin-right: 0 !important;
    }
    .mar-brand{
        margin-right: 0;
    }
}


.drop-b:after{
    display: none !important;
}

.div-tab{
    background: white;
    min-height: 50%;
    /* border: 1px solid #ddd; */
    border-radius: 11px;
}
.item-dro{
    padding: 4px 9px 15px 9px !important;
}
.item-dro:hover{
   background-color: #D9EDEE !important;
}
.item-dro.active{
    background-color: #D9EDEE !important;
 }
 .nav-style{
    margin-right: 12px;
    font-size: 20px;
    padding: 0 20px;
font-style: normal;
font-weight: 400;
 }
 .nav-style:hover{
    background:  #D9EDEE!important;
    border-bottom: 3px solid #042325;
 }
 .nav-style::after{
   display: none !important;
 }

 @media (max-width:375px){
    .padd-0{
        padding: 0 !important;
    }
}
.logo-image-class{
    width: 68%;
    object-fit: contain;
}
.navbar-brand{
    margin-right: 0 !important;
}
.item-dro.active{
    background-color:#D9EDEE !important;
    color: black !important;
}
.item-dro:hover{
    background-color:#D9EDEE !important;
    color: black !important;
}